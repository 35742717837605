import React from "react";
import InternshipListStudent from "./InternshipListStudent";

import './css/Boss.css'
function Student(){
    return(
        <div className="Student">
            <InternshipListStudent/>
        </div>
    );
}
export default Student;