import React from "react";
import "./css/EspaceEtudiant.css";
import { Link } from "react-router-dom";

function EspaceEtudiant(props) {
  return (
    <div className="EspaceEtudiant">
      <div className="Etudiant-title">
        <h1>Informations pour les employeurs</h1>
      </div>
      <div className="Etudiant-flex-container">
        <div className="Etudiant-flex-card">
          <div className="Etudiant-flex-title">
            <h2>Déroulement du stage</h2>
          </div>
          <div className="Etudiant-flex-content">
            <p>
              Les étudiants en informatique du Collège Montmorency doivent effectuer un stage de 15 semaines dans le cadre de leurs études.
              Le stage se déroule uniquement à la session d'hiver et est normalement du <b>20 janvier au 2 mai 2025</b>. 
              Il est possible de prendre entente pour débuter plus tôt (pas avant le 2 janvier) ou plus tard.
              Les 15 semaines sont basées sur une semaine de travail de 35 à 40 heures. 
              Il est possible d'adapter les dates pour des semaines à l'horaire différent.
              
            </p>
            <p>
              Les étudiants sont en recherche de stage à partir du mois d'août et cherchent activement durant tout l'automne.
              Nous avons deux profils de sortie que vous pouvez consulter dans la section  <Link to="/ProfilStagiaires">Profils stagiaires</Link>
            </p>
          </div>
        </div>
        <div className="Etudiant-flex-card">
          <div className="Etudiant-flex-title">
            <h2>Soumettre une offre de stage</h2>
          </div>
          <div className="Etudiant-flex-content">
            <p>
              Vous pouvez soumettre une offre de stage par courriel à <a href="mailto:slabranche@cmontmorency.qc.ca"> Sylvain Labranche</a>.
              Le format de l'offre est libre: nous désirons que le processus soit le plus près possible d'une vraie recherche d'emploi.
              Les informations pertinentes à inclure sont les suivantes:
              <ul>
                <li>Nom de l'entreprise et description</li>
                <li>Personne contact avec courriel et/ou numéro de téléphone</li>
                <li>Description des tâches du stage</li>
                <li>Lieu de stage et/ou les possibilités de télétravail</li>
                <li>Rémunération. À noter que la rémunération est facultative et à la discrétion de l'entreprise.</li>
                <li>Toute information que vous jugez pertinente</li>
              </ul>
              Vous recevrez une confirmation par courriel que votre offre est affichée sur une plate-forme interne.
            </p>
            <p>
              Les étudiants intéressés communiqueront directement avec vous par l'entremise des informations de contact fournies.
              Une fois l'entente de gré-à-gré conclue entre vous et l'étudiant, le coordonnateur communiquera avec vous au courant de l'automne pour confirmer le stage.
              Peu avant le début du stage, le collège vous enverra une entente officielle.
              Si le stage n'est pas rémunéré, le collège fournira aussi une preuve d'assurance.
            </p>
            <p>
              Il n'est pas possible d'obtenir de la part du coordonnateur une liste d'étudiants à contacter.
            </p>
          </div>
        </div>

        <div className="Etudiant-flex-card">
          <div className="Etudiant-flex-title">
            <h2>Responsabilités de l'employeur</h2>
          </div>
          <div className="Etudiant-flex-content">
            <p>
                Le stage se veut d'abord un milieu d'apprentissage bénéfique pour l'étudiant.
                L'employeur s'engage à le reconnaîtrie et à adapter ses attentes en fonction des capacités du stagiaire.
            </p>          
            <p>
                Le responsable du stage en entreprise sera contacté par un professeur superviseur pour une rencontre de 3 à 5 fois pendant les stage.
                Le responsable devra aussi remplir une évaluation à la fin du stage.
            </p>
          </div>
        </div>

        <div className="Etudiant-flex-card">
          <div className="Etudiant-flex-title">
            <h2>Questions</h2>
          </div>
          <div className="Etudiant-flex-content">
            <p>
              Veuillez contacter le coordonnateur des stages <a href="mailto:slabranche@cmontmorency.qc.ca"> Sylvain Labranche</a> pour toute question.
              Vous devriez avoir un retour dans en 2 jours ouvrables.

            </p>

          </div>
        </div>

 
      
        
      </div>
    </div>
  );
}
export default EspaceEtudiant;
