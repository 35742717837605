import React from "react";
import InternshipUpdateForm from "../forms/internshipUpdate/InternshipUpdateForm";

function InternshipUpdate(){
    return(
        <div className="InternshipUpdate">
            <InternshipUpdateForm/>
        </div>
    );    

}

export default InternshipUpdate;